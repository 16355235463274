import React from 'react';
import { motion } from 'framer-motion';
import { ArrowUpRight } from 'lucide-react';

interface ProblemCardProps {
  backgroundColor: string;
  problem: string;
  solution: string;
  index: number;
}

export const ProblemCard = ({ backgroundColor, problem, solution, index }: ProblemCardProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
      className="group relative p-8 rounded-2xl transition-all duration-500 hover:-translate-y-2 hover:shadow-xl"
      style={{ backgroundColor }}
    >
      {/* Decorative Elements */}
      <div className="absolute inset-0 rounded-2xl opacity-0 group-hover:opacity-100 transition-opacity duration-500">
        <div className="absolute top-0 left-0 w-24 h-24 rounded-tl-2xl border-t-2 border-l-2 border-black/10" />
        <div className="absolute bottom-0 right-0 w-24 h-24 rounded-br-2xl border-b-2 border-r-2 border-black/10" />
      </div>

      {/* Content */}
      <div className="relative">
        <div className="flex items-center justify-between mb-6">
          <span className="text-sm font-medium text-black/60">
            Problem #{index + 1}
          </span>
          <motion.div
            initial={{ rotate: 0 }}
            whileHover={{ rotate: 45 }}
            className="w-8 h-8 rounded-full bg-black/5 flex items-center justify-center"
          >
            <ArrowUpRight className="w-4 h-4 text-black/40" />
          </motion.div>
        </div>

        <h3 className="text-xl font-medium text-gray-900 mb-4 leading-tight min-h-[3rem]">
          {problem}
        </h3>

        <div className="h-[1px] w-12 bg-black/10 mb-4 transition-all duration-300 group-hover:w-full" />

        <p className="text-gray-700 text-base leading-relaxed">
          {solution}
        </p>
      </div>

      {/* Hover Effect Overlay */}
      <div 
        className="absolute inset-0 rounded-2xl opacity-0 group-hover:opacity-5 transition-opacity duration-500"
        style={{ 
          background: `radial-gradient(circle at center, ${backgroundColor}, transparent 70%)`
        }}
      />
    </motion.div>
  );
};