import React from 'react';
import { motion } from 'framer-motion';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { services, tagColors } from './data';

export const About = () => {
  const [emblaRef] = useEmblaCarousel({ 
    loop: true,
    dragFree: true,
    containScroll: "trimSnaps",
    align: 'start',
    slidesToScroll: 1,
    startIndex: 0
  }, [
    Autoplay({
      delay: 4000,
      stopOnInteraction: false,
      stopOnMouseEnter: true
    })
  ]);

  return (
    <section id="about" className="relative bg-black py-24">
      <div className="container mx-auto mb-16 text-center">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="inline-block bg-white/10 px-4 py-1 mb-6 rounded-lg"
        >
          <span className="text-white text-sm">ABOUT BRANDR DESIGN</span>
        </motion.div>
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.1 }}
          className="text-5xl md:text-6xl font-medium text-white leading-tight mb-6"
        >
          Transforming Ideas into
          <br />
          Digital Excellence
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.2 }}
          className="text-gray-400 text-lg max-w-2xl mx-auto"
        >
          We're more than a design studio - we're your partner in digital transformation.
        </motion.p>
      </div>

      <div className="overflow-hidden" ref={emblaRef}>
        <div className="flex">
          {services.map((service, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: 50 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="flex-[0_0_400px] min-w-0 px-4"
            >
              <div className="bg-[#0A0A0A] h-[600px] relative group overflow-hidden rounded-lg">
                <img 
                  src={service.image}
                  alt={service.title}
                  className="absolute inset-0 w-full h-full object-cover transition-transform duration-500 group-hover:scale-105"
                  loading="lazy"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent">
                  <div className="absolute bottom-0 left-0 right-0 p-8 space-y-4">
                    <h3 className="text-2xl font-medium text-white">{service.title}</h3>
                    <p className="text-gray-400">{service.description}</p>
                    <div className="flex flex-wrap gap-2 pt-4">
                      {service.tags.map((tag) => (
                        <span
                          key={tag}
                          className="px-3 py-1 text-xs tracking-wider rounded-lg"
                          style={{
                            backgroundColor: `${tagColors[tag]}20`,
                            color: tagColors[tag]
                          }}
                        >
                          {tag}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};