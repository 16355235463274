import React from 'react';
import { 
  Twitter, 
  Github, 
  Instagram, 
  Linkedin,
  Facebook,
  Youtube,
  Dribbble
} from 'lucide-react';
import { BehanceIcon } from '../icons/BehanceIcon';

export const SocialLinks = () => {
  return (
    <div>
      <h3 className="text-xl font-medium text-white mb-6">Follow Us</h3>
      <div className="grid grid-cols-4 gap-4">
        <a href="#" className="text-gray-500 hover:text-[#53fdca] transition-colors">
          <Twitter className="w-5 h-5" />
        </a>
        <a href="#" className="text-gray-500 hover:text-[#53fdca] transition-colors">
          <Facebook className="w-5 h-5" />
        </a>
        <a href="#" className="text-gray-500 hover:text-[#53fdca] transition-colors">
          <Instagram className="w-5 h-5" />
        </a>
        <a href="#" className="text-gray-500 hover:text-[#53fdca] transition-colors">
          <Linkedin className="w-5 h-5" />
        </a>
        <a href="#" className="text-gray-500 hover:text-[#53fdca] transition-colors">
          <Youtube className="w-5 h-5" />
        </a>
        <a href="#" className="text-gray-500 hover:text-[#53fdca] transition-colors">
          <Dribbble className="w-5 h-5" />
        </a>
        <a href="#" className="text-gray-500 hover:text-[#53fdca] transition-colors">
          <BehanceIcon />
        </a>
        <a href="#" className="text-gray-500 hover:text-[#53fdca] transition-colors">
          <Github className="w-5 h-5" />
        </a>
      </div>
    </div>
  );
};