import React from 'react';

export const BehanceIcon = () => (
  <svg 
    viewBox="0 0 24 24" 
    width="20" 
    height="20" 
    stroke="currentColor" 
    strokeWidth="2" 
    fill="none" 
    strokeLinecap="round" 
    strokeLinejoin="round"
  >
    <path d="M8.197 11.217c5.07 0 5.07 6.783 0 6.783H2v-6.783h6.197z" />
    <path d="M2 8.079h6c4.617 0 4.617-5.879 0-5.879H2v5.879z" />
    <path d="M14 5.5h8" />
    <path d="M18 15.5c2.317 0 4.5-1.686 4.5-4s-2.183-4-4.5-4c-2.317 0-4.5 1.686-4.5 4s2.183 4 4.5 4z" />
  </svg>
);