import React from 'react';
import { motion } from 'framer-motion';

export const PricingHeader = () => {
  return (
    <div className="text-center mb-16">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="inline-block bg-white/10 px-4 py-1 mb-6"
      >
        <span className="text-white text-sm">PRICING PLANS</span>
      </motion.div>
      
      <motion.h2
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="text-4xl lg:text-5xl font-medium text-white leading-tight mb-6"
      >
        No long-term commitments
      </motion.h2>
      
      <motion.p
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        className="text-gray-400 text-lg max-w-2xl mx-auto"
      >
        Choose the perfect plan for your needs. Cancel or pause anytime.
      </motion.p>
    </div>
  );
};