import React from 'react';
import { ProblemCard } from './ProblemCard';
import { problems } from './data';

export const ProblemsGrid = () => {
  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-7xl mx-auto">
      {problems.map((problem, index) => (
        <ProblemCard
          key={index}
          index={index}
          backgroundColor={problem.backgroundColor}
          problem={problem.problem}
          solution={problem.solution}
        />
      ))}
    </div>
  );
};