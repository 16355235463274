import { 
  Palette, 
  Zap, 
  Layout, 
  Users, 
  ArrowUpRight, 
  Code 
} from 'lucide-react';
import { Feature } from './types';

export const features: Feature[] = [
  {
    icon: Palette,
    title: "Design Excellence",
    description: "Crafting pixel-perfect designs that elevate your brand identity",
    color: "#53fdca",
    image: "https://images.unsplash.com/photo-1561070791-2526d30994b5?q=80&w=2000&auto=format&fit=crop",
    imageAlt: "Designer working on creative brand identity project"
  },
  {
    icon: Zap,
    title: "Rapid Delivery",
    description: "Fast turnaround times without compromising on quality",
    color: "#0066FF",
    image: "https://images.unsplash.com/photo-1600132806370-bf17e65e942f?q=80&w=2000&auto=format&fit=crop",
    imageAlt: "Team collaborating on digital project with quick iterations"
  },
  {
    icon: Layout,
    title: "Custom Solutions",
    description: "Tailored design solutions that match your unique needs",
    color: "#FF3366",
    image: "https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?q=80&w=2000&auto=format&fit=crop",
    imageAlt: "Custom web design and development solutions"
  },
  {
    icon: Users,
    title: "Dedicated Team",
    description: "Expert designers committed to your project's success",
    color: "#9966FF",
    image: "https://images.unsplash.com/photo-1600880292203-757bb62b4baf?q=80&w=2000&auto=format&fit=crop",
    imageAlt: "Professional design team collaboration session"
  },
  {
    icon: Code,
    title: "Modern Tech",
    description: "Using cutting-edge tools and technologies",
    color: "#FFCC33",
    image: "https://images.unsplash.com/photo-1581092334651-ddf26d9a09d0?q=80&w=2000&auto=format&fit=crop",
    imageAlt: "Modern technology and development workspace"
  },
  {
    icon: ArrowUpRight,
    title: "Scalable Process",
    description: "Efficient workflows that grow with your business",
    color: "#FF6633",
    image: "https://images.unsplash.com/photo-1553877522-43269d4ea984?q=80&w=2000&auto=format&fit=crop",
    imageAlt: "Scalable business process visualization"
  }
];