import React from 'react';
import { HeroBackground } from './HeroBackground';
import { HeroContent } from './HeroContent';

export const Hero = () => {
  return (
    <section className="relative pt-48 pb-24 bg-white overflow-hidden">
      <HeroBackground />
      <div className="container mx-auto relative z-10">
        <HeroContent />
      </div>
    </section>
  );
};