import React from 'react';
import { ProblemsHeader } from './ProblemsHeader';
import { ProblemsGrid } from './ProblemsGrid';

export const Problems = () => {
  return (
    <section className="relative bg-white py-24 overflow-hidden">
      {/* Background Image */}
      <div className="absolute top-0 -left-24 w-[600px] h-[600px] pointer-events-none">
        <img
          src="https://new.brandrdesign.com/saturn-assets/images/features/start-left-bottom.png"
          alt=""
          className="w-full h-full object-contain"
        />
      </div>

      {/* Content */}
      <div className="container mx-auto px-4 relative">
        <ProblemsHeader />
        <ProblemsGrid />
      </div>
    </section>
  );
};