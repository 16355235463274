import React from 'react';
import { Link } from 'react-router-dom';

interface MobileMenuProps {
  isOpen: boolean;
  onClose: () => void;
  onLoginClick: () => void;
}

export const MobileMenu = ({ isOpen, onClose, onLoginClick }: MobileMenuProps) => {
  return (
    <div 
      className={`md:hidden fixed inset-x-0 top-20 bg-white/95 backdrop-blur-lg transition-all duration-300 ${
        isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4 pointer-events-none'
      }`}
    >
      <div className="container mx-auto px-4 py-6 flex flex-col gap-4">
        <a 
          href="#about" 
          className="text-[#1E2238] hover:text-[#1E2238]/80 transition-colors px-4 py-2 rounded-lg hover:bg-[#1E2238]/5"
          onClick={onClose}
        >
          About
        </a>
        <Link 
          to="/projects" 
          className="text-[#1E2238] hover:text-[#1E2238]/80 transition-colors px-4 py-2 rounded-lg hover:bg-[#1E2238]/5"
          onClick={onClose}
        >
          Work
        </Link>
        <Link 
          to="/services" 
          className="text-[#1E2238] hover:text-[#1E2238]/80 transition-colors px-4 py-2 rounded-lg hover:bg-[#1E2238]/5"
          onClick={onClose}
        >
          Services
        </Link>
        <a 
          href="#pricing" 
          className="text-[#1E2238] hover:text-[#1E2238]/80 transition-colors px-4 py-2 rounded-lg hover:bg-[#1E2238]/5"
          onClick={onClose}
        >
          Pricing
        </a>
        <button 
          onClick={() => {
            onLoginClick();
            onClose();
          }}
          className="text-left text-[#1E2238] hover:text-[#1E2238]/80 transition-colors px-4 py-2 rounded-lg hover:bg-[#1E2238]/5"
        >
          Login
        </button>
      </div>
    </div>
  );
};