import React, { createContext, useContext, useState, useMemo } from 'react';
import { Project } from './types';
import { projects } from './data';

interface ProjectsContextType {
  categories: string[];
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
  filteredProjects: Project[];
}

const ProjectsContext = createContext<ProjectsContextType | undefined>(undefined);

export const ProjectsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [selectedCategory, setSelectedCategory] = useState("All");

  const categories = useMemo(() => ["All", "Branding", "Web Design", "Marketing", "UI/UX"], []);

  const filteredProjects = useMemo(() => {
    return selectedCategory === "All" 
      ? projects 
      : projects.filter(project => project.category === selectedCategory);
  }, [selectedCategory]);

  return (
    <ProjectsContext.Provider value={{
      categories,
      selectedCategory,
      setSelectedCategory,
      filteredProjects
    }}>
      {children}
    </ProjectsContext.Provider>
  );
};

export const useProjectsContext = () => {
  const context = useContext(ProjectsContext);
  if (!context) {
    throw new Error('useProjectsContext must be used within a ProjectsProvider');
  }
  return context;
};