import React from 'react';
import { Link } from 'react-router-dom';
import { 
  Twitter, 
  Github, 
  Instagram, 
  Linkedin,
  Facebook,
  Youtube,
  Dribbble
} from 'lucide-react';
import { SocialLinks } from './SocialLinks';
import { FooterLinks } from './FooterLinks';
import { LegalLinks } from './LegalLinks';

export const Footer = () => {
  return (
    <footer className="bg-black border-t border-white/10">
      <div className="container mx-auto px-4 py-16">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12">
          {/* Company Info */}
          <div>
            <div className="flex items-center gap-3 mb-6">
              <img 
                src="https://content.app-sources.com/s/498134316199503221/uploads/Images/brandr_design_logo_3-4111031.webp?format=webp"
                alt="Brandr Design Logo"
                className="w-10 h-7 object-contain"
              />
              <span className="text-2xl font-medium text-white">Brandr Design</span>
            </div>
            <p className="text-gray-400">
              Transform your brand with unlimited design possibilities
            </p>
          </div>

          {/* Quick Links */}
          <FooterLinks />

          {/* Contact Info */}
          <div>
            <h3 className="text-xl font-medium text-white mb-6">Contact</h3>
            <p className="text-gray-400 mb-4">
              Available worldwide, 24/7 online support
            </p>
            <a 
              href="mailto:hello@brandrdesign.com" 
              className="text-gray-400 hover:text-[#53fdca] transition-colors"
            >
              hello@brandrdesign.com
            </a>
          </div>

          {/* Social Links */}
          <SocialLinks />
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="border-t border-white/10">
        <div className="container mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-center gap-4">
            <p className="text-sm text-gray-400">
              © 2024 Brandr Design. All rights reserved.
            </p>
            <LegalLinks />
          </div>
        </div>
      </div>
    </footer>
  );
};