import React from 'react';

export const HeroBackground = () => {
  return (
    <div className="absolute inset-0 z-0">
      <img 
        src="https://new.brandrdesign.com/saturn-assets/images/headers/light-orange-blue-1.png"
        alt="Background Pattern"
        className="absolute top-[10%] right-0 w-[75%] h-auto object-contain animate-float"
      />
    </div>
  );
};