import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Layout } from './components/layout/Layout';
import { ErrorBoundary } from './components/ErrorBoundary';
import { Home } from './pages/Home';
import { Projects } from './pages/Projects';
import { Services } from './pages/Services';
import { ProjectTemplate } from './pages/ProjectTemplate';
import { LandingPageService } from './pages/LandingPageService';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { TermsOfService } from './pages/TermsOfService';

export const App = () => {
  return (
    <ErrorBoundary>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects/express-brand-identity" element={<ProjectTemplate />} />
          <Route path="/services/landing-pages" element={<LandingPageService />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
      </Layout>
    </ErrorBoundary>
  );
};