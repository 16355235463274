import React from 'react';
import { motion } from 'framer-motion';

export const ProblemsHeader = () => {
  return (
    <div className="grid md:grid-cols-2 gap-12 mb-24 items-center">
      <div>
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-5xl md:text-6xl lg:text-7xl font-medium text-[#1E2238] leading-tight mb-8"
        >
          Say Goodbye to{' '}
          <span className="font-playfair italic">Design Bottlenecks</span>.
        </motion.h2>
        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.1 }}
          className="text-xl text-gray-600 leading-relaxed max-w-xl"
        >
          We understand the challenges of keeping up with design demands. That's why we've built a service to eliminate the hassle and keep your projects moving.
        </motion.p>
      </div>

      <motion.div
        initial={{ opacity: 0, scale: 0.95 }}
        whileInView={{ opacity: 1, scale: 1 }}
        viewport={{ once: true }}
        className="flex justify-center"
      >
        <img
          src="https://new.brandrdesign.com/saturn-assets/images/features/violet-image.png"
          alt="Design Process Illustration"
          className="w-[80%] h-auto rounded-lg shadow-xl"
        />
      </motion.div>
    </div>
  );
};