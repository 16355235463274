import React from 'react';
import { motion } from 'framer-motion';
import { Clock, Palette, Zap, Layout, Users, ArrowUpRight } from 'lucide-react';

const benefits = [
  {
    icon: <Clock className="w-6 h-6" />,
    title: "24-48 Hour Turnaround",
    description: "Get your designs delivered quickly without compromising on quality",
    color: "#53fdca"
  },
  {
    icon: <Palette className="w-6 h-6" />,
    title: "Unlimited Requests",
    description: "Submit as many design requests as you need, we'll handle them all",
    color: "#0066FF"
  },
  {
    icon: <Zap className="w-6 h-6" />,
    title: "Unlimited Revisions",
    description: "We'll revise until you're 100% satisfied with the results",
    color: "#FF3366"
  },
  {
    icon: <Layout className="w-6 h-6" />,
    title: "Dedicated Team",
    description: "Work with a consistent team that knows your brand inside out",
    color: "#9966FF"
  },
  {
    icon: <Users className="w-6 h-6" />,
    title: "Brand Consistency",
    description: "Maintain perfect brand consistency across all your designs",
    color: "#FFCC33"
  },
  {
    icon: <ArrowUpRight className="w-6 h-6" />,
    title: "Quick Iterations",
    description: "Fast feedback loops and rapid design iterations",
    color: "#FF6633"
  }
];

export const Benefits = () => {
  return (
    <section className="relative bg-black py-24">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block bg-white/10 px-4 py-1 mb-6"
          >
            <span className="text-white text-sm">BENEFITS</span>
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-5xl md:text-6xl font-medium text-white leading-tight mb-6"
          >
            Experience the power of
            <br />
            unlimited design
          </motion.h2>
        </div>

        {/* Benefits Grid */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 max-w-6xl mx-auto">
          {benefits.map((benefit, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="group relative flex items-start gap-6 hover:translate-x-2 transition-transform duration-300"
            >
              {/* Number */}
              <div className="absolute -left-8 top-0 text-white/10 text-5xl font-medium">
                {(index + 1).toString().padStart(2, '0')}
              </div>

              {/* Icon */}
              <div 
                className="flex-shrink-0 w-16 h-16 rounded-full flex items-center justify-center transition-transform duration-300 group-hover:scale-110"
                style={{ 
                  backgroundColor: `${benefit.color}10`,
                  color: benefit.color
                }}
              >
                {benefit.icon}
              </div>

              {/* Content */}
              <div>
                <h3 className="text-2xl font-medium text-white mb-3 flex items-center gap-2">
                  {benefit.title}
                  <ArrowUpRight className="w-5 h-5 opacity-0 group-hover:opacity-100 transition-opacity" />
                </h3>
                <p className="text-gray-400 leading-relaxed">
                  {benefit.description}
                </p>
              </div>

              {/* Hover Line */}
              <div 
                className="absolute -bottom-8 left-0 w-full h-[1px] scale-x-0 group-hover:scale-x-100 transition-transform duration-300"
                style={{ backgroundColor: benefit.color }}
              />
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};