import React from 'react';
import { Link } from 'react-router-dom';

export const FooterLinks = () => {
  return (
    <div>
      <h3 className="text-xl font-medium text-white mb-6">Quick Links</h3>
      <ul className="space-y-4">
        {[
          { label: 'About Us', href: '#about' },
          { label: 'Our Work', href: '/projects' },
          { label: 'Services', href: '/services' },
          { label: 'Pricing', href: '#pricing' },
          { label: 'Landing Pages', href: '/services/landing-pages' }
        ].map((link) => (
          <li key={link.label}>
            <a 
              href={link.href}
              className="text-gray-400 hover:text-[#53fdca] transition-colors"
            >
              {link.label}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};