import React from 'react';
import { Link } from 'react-router-dom';

export const LegalLinks = () => {
  return (
    <div className="flex gap-6 text-sm">
      <Link to="/privacy-policy" className="text-gray-400 hover:text-[#53fdca] transition-colors">
        Privacy Policy
      </Link>
      <Link to="/terms-of-service" className="text-gray-400 hover:text-[#53fdca] transition-colors">
        Terms of Service
      </Link>
      <a href="#" className="text-gray-400 hover:text-[#53fdca] transition-colors">
        Cookie Policy
      </a>
    </div>
  );
};