import React, { useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import jsVectorMap from 'jsvectormap';
import 'jsvectormap/dist/maps/world.js';

export const GlobalReach = () => {
  const mapRef = useRef(null);

  useEffect(() => {
    if (mapRef.current) {
      const map = new jsVectorMap({
        selector: '#map',
        map: 'world',
        backgroundColor: 'transparent',
        draggable: false, // Disable dragging
        zoomButtons: false,
        zoomOnScroll: false, // Disable zoom on scroll
        zoomOnScrollSpeed: false, // Disable zoom speed
        zoomMax: 1, // Set max zoom to 1 to prevent zooming
        zoomMin: 1, // Set min zoom to 1 to prevent zooming
        markers: [
          { name: 'New York', coords: [40.7128, -74.0060] },
          { name: 'London', coords: [51.5074, -0.1278] },
          { name: 'Dubai', coords: [25.2048, 55.2708] }, // Dubai coordinates
          { name: 'Abu Dhabi', coords: [24.4539, 54.3773] }, // Added another UAE point
          { name: 'Singapore', coords: [1.3521, 103.8198] },
          { name: 'Tokyo', coords: [35.6762, 139.6503] },
          { name: 'Sydney', coords: [-33.8688, 151.2093] },
          { name: 'São Paulo', coords: [-23.5505, -46.6333] }
        ],
        markerStyle: {
          initial: {
            fill: '#0066FF',
            stroke: '#0066FF',
            r: 6
          },
          hover: {
            fill: '#fff',
            stroke: '#0066FF',
            r: 8
          }
        },
        regionStyle: {
          initial: {
            fill: '#1a1a1a',
            stroke: '#666666',
            strokeWidth: 0.5,
            fillOpacity: 1
          },
          hover: {
            fill: '#222222',
            cursor: 'default' // Change cursor to default since we disabled interactions
          },
          selected: {
            fill: '#222222'
          }
        }
      });

      return () => {
        map.destroy();
      };
    }
  }, []);

  return (
    <section className="relative bg-black py-24 overflow-hidden">
      <div className="container mx-auto px-4">
        {/* Header Content - Centered */}
        <div className="text-center max-w-3xl mx-auto mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block bg-white/10 px-4 py-1 mb-6"
          >
            <span className="text-white text-sm">GLOBAL PRESENCE</span>
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-4xl lg:text-5xl font-medium text-white leading-tight mb-6"
          >
            Serving Clients
            <br />
            Worldwide
          </motion.h2>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-gray-400 text-lg"
          >
            From startups to enterprises, we deliver exceptional design services to clients across every timezone.
          </motion.p>
        </div>

        {/* Map Container with side margins */}
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          className="relative h-[600px] bg-transparent max-w-[90%] mx-auto"
          ref={mapRef}
        >
          <div id="map" className="h-full w-full" />
        </motion.div>
      </div>
    </section>
  );
};