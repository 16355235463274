import React from 'react';
import { PricingHeader } from './PricingHeader';
import { PricingPlans } from './PricingPlans';

export const HomePricing = () => {
  return (
    <section id="pricing" className="relative bg-black py-24">
      <div className="container mx-auto px-4">
        <PricingHeader />
        <PricingPlans />
      </div>
    </section>
  );
};