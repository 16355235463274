import { StaticImageData } from 'next/image';

export interface Testimonial {
  name: string;
  role: string;
  company: string;
  image: string;
  quote: string;
  rating: number;
}

export const testimonials: Testimonial[] = [
  {
    name: "Sarah Johnson",
    role: "Marketing Director",
    company: "TechCorp",
    image: "https://images.unsplash.com/photo-1494790108377-be9c29b29330?q=80&w=250&h=250&auto=format&fit=crop",
    quote: "Working with Brandr Design has been transformative for our brand. Their unlimited design model gives us the flexibility we need.",
    rating: 5
  },
  {
    name: "Michael Chen",
    role: "Product Manager",
    company: "InnovateLabs",
    image: "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?q=80&w=250&h=250&auto=format&fit=crop",
    quote: "The quality and turnaround time are exceptional. It's like having an entire design team at your fingertips.",
    rating: 5
  },
  {
    name: "Emily Rodriguez",
    role: "Creative Director",
    company: "DesignFlow",
    image: "https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=250&h=250&auto=format&fit=crop",
    quote: "Their attention to detail and brand consistency across all deliverables is impressive. Highly recommended!",
    rating: 5
  },
  {
    name: "David Kim",
    role: "CEO",
    company: "StartupX",
    image: "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?q=80&w=250&h=250&auto=format&fit=crop",
    quote: "The subscription model is perfect for our growing business. We get high-quality designs without breaking the bank.",
    rating: 5
  },
  {
    name: "Lisa Thompson",
    role: "Brand Manager",
    company: "GrowthCo",
    image: "https://images.unsplash.com/photo-1534528741775-53994a69daeb?q=80&w=250&h=250&auto=format&fit=crop",
    quote: "Brandr Design has exceeded our expectations. Their team is responsive, creative, and always delivers on time.",
    rating: 5
  },
  {
    name: "James Wilson",
    role: "Founder",
    company: "TechStart",
    image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?q=80&w=250&h=250&auto=format&fit=crop",
    quote: "The best decision we made was partnering with Brandr Design. They truly understand our vision.",
    rating: 5
  },
  {
    name: "Anna Martinez",
    role: "Design Lead",
    company: "CreativeHub",
    image: "https://images.unsplash.com/photo-1580489944761-15a19d654956?q=80&w=250&h=250&auto=format&fit=crop",
    quote: "Their process is seamless, and the results are consistently outstanding. A game-changer for our design needs.",
    rating: 5
  },
  {
    name: "Robert Lee",
    role: "COO",
    company: "InnovateX",
    image: "https://images.unsplash.com/photo-1519085360753-af0119f7cbe7?q=80&w=250&h=250&auto=format&fit=crop",
    quote: "The flexibility and quality of service are unmatched. They've become an essential part of our team.",
    rating: 5
  }
];