import React from 'react';
import { PricingCard } from './PricingCard';
import { usePricingPlans } from './usePricingPlans';

export const PricingPlans = () => {
  const plans = usePricingPlans();

  return (
    <div className="flex justify-center">
      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl">
        {plans.map((plan, index) => (
          <PricingCard
            key={plan.name}
            plan={plan}
            index={index}
          />
        ))}
      </div>
    </div>
  );
};