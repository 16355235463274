import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Check, Zap } from 'lucide-react';
import { PricingPlan } from './types';

interface PricingCardProps {
  plan: PricingPlan;
  index: number;
}

export const PricingCard = ({ plan, index }: PricingCardProps) => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      transition={{ delay: index * 0.1 }}
      className={`relative rounded-2xl ${
        plan.name === 'Founder' 
          ? 'bg-white text-black' 
          : 'border-white/10 border bg-white/5 backdrop-blur-sm text-white'
      }`}
    >
      {plan.highlight && (
        <div className="absolute -top-4 left-0 right-0 flex justify-center">
          <span className="bg-white text-black px-4 py-1 text-sm font-medium rounded-full">
            Most Popular
          </span>
        </div>
      )}

      <div className="p-8">
        <h3 className={`text-2xl font-medium mb-2 ${plan.name === 'Founder' ? 'text-black' : 'text-white'}`}>
          {plan.name}
        </h3>
        <p className={`${plan.name === 'Founder' ? 'text-gray-600' : 'text-gray-400'} mb-6`}>
          {plan.description}
        </p>
        
        <div className="flex items-baseline gap-2 mb-8">
          <span className={`text-4xl font-medium ${plan.name === 'Founder' ? 'text-black' : 'text-white'}`}>
            ${plan.price}
          </span>
          <span className={plan.name === 'Founder' ? 'text-gray-600' : 'text-gray-400'}>
            /month
          </span>
        </div>

        <button 
          className={`w-full py-4 px-6 flex items-center justify-center gap-2 group mb-8 rounded-lg ${
            plan.name === 'Founder'
              ? 'bg-[#0066FF] text-white hover:bg-[#0052CC]'
              : 'bg-[#0066FF] text-white hover:bg-[#0052CC]'
          } transition-colors`}
        >
          Get started
          {plan.highlight ? (
            <Zap className="w-5 h-5" />
          ) : (
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          )}
        </button>

        <ul className="space-y-4">
          {plan.features.map((feature, i) => (
            <li key={i} className="flex items-start gap-3">
              <Check className={`w-5 h-5 mt-0.5 ${
                plan.name === 'Founder'
                  ? 'text-black'
                  : 'text-white/60'
              }`} />
              <span className={
                plan.name === 'Founder' ? 'text-gray-600' : 'text-gray-300'
              }>
                {feature}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </motion.div>
  );
};