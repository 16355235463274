import React from 'react';
import { motion } from 'framer-motion';
import { Plus, Minus } from 'lucide-react';
import { Disclosure } from '@headlessui/react';

const faqs = [
  {
    question: "How does the unlimited design subscription work?",
    answer: "Our subscription model allows you to submit unlimited design requests, one at a time. Once you submit a request, we'll work on it within 24-48 hours and continue revising until you're completely satisfied."
  },
  {
    question: "What types of design work do you handle?",
    answer: "We cover a wide range of design needs including web design, brand identity, marketing materials, UI/UX design, social media graphics, and more. Our team is versatile and experienced across multiple design disciplines."
  },
  {
    question: "How fast will I receive my designs?",
    answer: "Most design requests are completed within 24-48 hours. Complex projects may take longer, but we'll always communicate timelines clearly and keep you updated on progress."
  },
  {
    question: "Can I request revisions to the designs?",
    answer: "Absolutely! We offer unlimited revisions until you're 100% satisfied with the results. Simply provide your feedback, and we'll make the necessary adjustments."
  },
  {
    question: "What if I want to cancel my subscription?",
    answer: "You can cancel your subscription at any time. There are no long-term contracts or cancellation fees. You'll have access to the service until the end of your current billing period."
  }
];

export const FAQ = () => {
  return (
    <section className="relative bg-black py-24">
      <div className="container mx-auto px-4">
        <div className="max-w-3xl mx-auto">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <span className="text-[#FDBA12] text-sm tracking-wider mb-4 block">FAQ</span>
            <h2 className="text-4xl font-medium text-white mb-6">
              Frequently Asked Questions
            </h2>
            <p className="text-gray-400">
              Everything you need to know about our design subscription service
            </p>
          </motion.div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                viewport={{ once: true }}
                transition={{ delay: index * 0.1 }}
              >
                <Disclosure>
                  {({ open }) => (
                    <div className="border-b border-white/10">
                      <Disclosure.Button className="w-full py-6 flex justify-between items-center text-left">
                        <span className="text-lg font-medium text-white">
                          {faq.question}
                        </span>
                        {open ? (
                          <Minus className="w-5 h-5 text-gray-400" />
                        ) : (
                          <Plus className="w-5 h-5 text-gray-400" />
                        )}
                      </Disclosure.Button>
                      <Disclosure.Panel className="pb-6 text-gray-400 leading-relaxed">
                        {faq.answer}
                      </Disclosure.Panel>
                    </div>
                  )}
                </Disclosure>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};