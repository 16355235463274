import React from 'react';
import { Hero } from '../components/hero/Hero';
import { Problems } from '../components/problems/Problems';
import { About } from '../components/about/About';
import { Projects } from '../components/projects/Projects';
import { Process } from '../components/process/Process';
import { Benefits } from '../components/benefits/Benefits';
import { Testimonials } from '../components/testimonials/Testimonials';
import { Team } from '../components/team/Team';
import { HomePricing } from '../components/pricing/HomePricing';
import { FAQ } from '../components/faq/FAQ';
import { CTA } from '../components/cta/CTA';
import { GlobalReach } from '../components/global/GlobalReach';
import { Features } from '../components/features/Features';

export const Home = () => {
  return (
    <>
      <Hero />
      <Problems />
      <About />
      <Projects />
      <Process />
      <Benefits />
      <Features />
      <GlobalReach />
      <Testimonials />
      <Team />
      <HomePricing />
      <FAQ />
      <CTA />
    </>
  );
};