import React from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { motion } from 'framer-motion';
import { Star } from 'lucide-react';

interface Testimonial {
  name: string;
  role: string;
  image: string;
  quote: string;
  rating: number;
}

interface TestimonialCarouselProps {
  testimonials: Testimonial[];
}

export const TestimonialCarousel = ({ testimonials }: TestimonialCarouselProps) => {
  const [emblaRef] = useEmblaCarousel(
    { 
      loop: true,
      align: 'start',
      skipSnaps: false
    },
    [Autoplay({ delay: 5000 })]
  );

  return (
    <div className="overflow-hidden" ref={emblaRef}>
      <div className="flex">
        {testimonials.map((testimonial, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ delay: index * 0.1 }}
            className="flex-[0_0_100%] min-w-0 md:flex-[0_0_50%] lg:flex-[0_0_33.33%] px-4"
          >
            <div className="bg-[#0A0A0A] p-8 h-full relative group">
              {/* Decorative Lines */}
              <motion.div 
                className="absolute inset-0"
                initial={{ opacity: 0.1 }}
                whileHover={{ opacity: 0.2 }}
                transition={{ duration: 0.3 }}
              >
                <motion.div 
                  className="absolute top-0 left-0 w-[1px] h-full"
                  initial={{ scaleY: 0 }}
                  whileHover={{ scaleY: 1 }}
                  transition={{ duration: 0.3 }}
                  style={{
                    background: 'linear-gradient(to bottom, #0066FF, #0044AA)'
                  }}
                />
                <motion.div 
                  className="absolute top-0 right-0 w-[1px] h-full"
                  initial={{ scaleY: 0 }}
                  whileHover={{ scaleY: 1 }}
                  transition={{ duration: 0.3 }}
                  style={{
                    background: 'linear-gradient(to bottom, #0066FF, #0044AA)'
                  }}
                />
                <motion.div 
                  className="absolute top-0 left-0 w-full h-[1px]"
                  initial={{ scaleX: 0 }}
                  whileHover={{ scaleX: 1 }}
                  transition={{ duration: 0.3 }}
                  style={{
                    background: 'linear-gradient(to right, #0066FF, #0044AA)'
                  }}
                />
                <motion.div 
                  className="absolute bottom-0 left-0 w-full h-[1px]"
                  initial={{ scaleX: 0 }}
                  whileHover={{ scaleX: 1 }}
                  transition={{ duration: 0.3 }}
                  style={{
                    background: 'linear-gradient(to right, #0066FF, #0044AA)'
                  }}
                />
              </motion.div>

              {/* Content */}
              <div className="relative z-10">
                <div className="flex items-center gap-4 mb-6">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-12 h-12 rounded-full object-cover"
                  />
                  <div>
                    <h4 className="font-medium text-white">{testimonial.name}</h4>
                    <p className="text-sm text-gray-400">{testimonial.role}</p>
                  </div>
                </div>
                
                <div className="flex gap-1 mb-4">
                  {Array.from({ length: testimonial.rating }).map((_, i) => (
                    <Star key={i} className="w-4 h-4 fill-[#FDBA12] text-[#FDBA12]" />
                  ))}
                </div>
                
                <p className="text-gray-400 leading-relaxed">{testimonial.quote}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};