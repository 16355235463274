import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Zap } from 'lucide-react';
import { scrollToSection } from '../../lib/utils/scroll';
import { HeroEyebrow } from './HeroEyebrow';

export const HeroContent = () => {
  const handleDemoCall = () => {
    window.location.href = 'https://brandrdesign.xbrandr.io/call';
  };

  const handleStartSubscription = () => {
    scrollToSection('pricing');
  };

  return (
    <div className="flex flex-col items-center justify-center text-center px-4">
      <HeroEyebrow />
      
      <motion.h1
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1 }}
        className="text-7xl sm:text-8xl md:text-[96px] font-medium leading-[0.95] tracking-tight mb-6 max-w-4xl text-[#1E2238]"
      >
        Design Excellence
        <br />
        for <span className="font-playfair italic font-bold">Every Brand</span>
      </motion.h1>

      <motion.p
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="text-xl sm:text-2xl text-[#1E2238]/60 mb-12 max-w-2xl px-4"
      >
        Get access to a professional team of designers, delivering custom work fast with flexible pricing.
      </motion.p>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3 }}
        className="flex flex-col sm:flex-row gap-4 justify-center w-full sm:w-auto px-4"
      >
        <button 
          onClick={handleStartSubscription}
          className="w-full sm:w-auto btn group relative px-8 py-3 bg-[#FF460C] text-white font-medium overflow-hidden hover:bg-[#FF460C]/90 transition-colors rounded-lg"
        >
          <span className="relative z-10 flex items-center justify-center gap-2">
            Start your subscription
            <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
          </span>
        </button>
        
        <button 
          onClick={handleDemoCall}
          className="w-full sm:w-auto btn px-8 py-3 border border-[#1E2238]/20 text-[#1E2238] font-medium hover:bg-[#1E2238]/5 transition-colors flex items-center justify-center gap-2 rounded-lg"
        >
          <Zap className="w-5 h-5" />
          Book a demo call
        </button>
      </motion.div>
    </div>
  );
};