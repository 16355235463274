export interface Problem {
  backgroundColor: string;
  problem: string;
  solution: string;
}

export const problems: Problem[] = [
  {
    backgroundColor: '#A5F4D8',
    problem: 'Inconsistent Quality from Freelancers or Agencies',
    solution: 'Our team delivers consistent, professional designs every time, so you never have to worry about uneven results.'
  },
  {
    backgroundColor: '#FFAF9B',
    problem: 'Expensive and Unpredictable Costs',
    solution: "With flexible subscription plans, you always know what you're paying—no surprises, just great design."
  },
  {
    backgroundColor: '#CABDFF',
    problem: 'Missed Deadlines and Long Turnarounds',
    solution: 'We pride ourselves on fast delivery, ensuring you meet your deadlines without compromising on quality.'
  },
  {
    backgroundColor: '#FFF3B0',
    problem: 'Overwhelmed with Too Many Design Needs',
    solution: 'From social media graphics to landing pages, our team handles it all, giving you the freedom to focus on what you do best.'
  }
];