import React from 'react';
import { motion } from 'framer-motion';
import { TestimonialCarousel } from './TestimonialCarousel';
import { testimonials } from './data';

export const Testimonials = () => {
  return (
    <section className="relative bg-black py-24">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="flex flex-col lg:flex-row justify-between items-start mb-16 gap-8">
          <div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="inline-block bg-white/10 px-4 py-1 mb-6"
            >
              <span className="text-white text-sm">TESTIMONIALS</span>
            </motion.div>
            
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="text-4xl lg:text-5xl font-medium text-white leading-tight"
            >
              Trusted by Forward-Thinking
              <br />
              Brands Worldwide
            </motion.h2>
          </div>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg lg:text-right"
          >
            Join hundreds of satisfied clients who have
            <br className="hidden lg:block" />
            transformed their design process.
          </motion.p>
        </div>

        {/* Testimonials Carousel */}
        <TestimonialCarousel testimonials={testimonials} />
      </div>
    </section>
  );
};