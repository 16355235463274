import React from 'react';
import { motion } from 'framer-motion';

const steps = [
  {
    title: "Subscribe to a plan",
    description: "Subscribe to a plan & request as many designs as you'd like.",
    image: "https://framerusercontent.com/images/x6Esv3tqb8Zrrh8DTPaosjLw.png"
  },
  {
    title: "Add a task",
    description: "Manage your design board using Trello. View tasks and status.",
    image: "https://framerusercontent.com/images/e8OPzk8ThluNmUAvAKHJYHMI.png?scale-down-to=512"
  },
  {
    title: "Enjoy results",
    description: "Receive your completed tasks in 2-4 business days.",
    image: "https://framerusercontent.com/images/iEeJfkxEuldMI93WJ0K6vVYYQ.png?scale-down-to=512"
  }
];

export const Process = () => {
  return (
    <section className="relative bg-black py-24">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="mb-20">
          <div className="inline-block bg-white/10 px-4 py-1 rounded-lg mb-6">
            <span className="text-white text-sm">How it works?</span>
          </div>
          <h2 className="text-5xl md:text-6xl font-medium mb-4 text-white">
            Pay and get started
            <br />
            right away
          </h2>
          <p className="text-xl text-gray-400">
            Design subscriptions for everyone. Pause or cancel anytime.
          </p>
        </div>

        {/* Cards */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {steps.map((step, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="bg-[#0A0A0A] border border-white/10 rounded-lg"
            >
              <div className="p-8">
                <h3 className="text-2xl font-medium mb-3 text-white">{step.title}</h3>
                <p className="text-gray-400 mb-8">{step.description}</p>
                <div className="aspect-square overflow-hidden rounded-lg">
                  <img
                    src={step.image}
                    alt={step.title}
                    className="w-full h-full object-contain bg-[#0A0A0A]"
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};