import React from 'react';
import { Link } from 'react-router-dom';

export const NavLinks = () => {
  return (
    <div className="flex items-center gap-8">
      <a href="#about" className="text-[#1E2238] hover:text-[#1E2238]/80 transition-colors font-medium">
        About
      </a>
      <Link to="/projects" className="text-[#1E2238] hover:text-[#1E2238]/80 transition-colors font-medium">
        Work
      </Link>
      <Link to="/services" className="text-[#1E2238] hover:text-[#1E2238]/80 transition-colors font-medium">
        Services
      </Link>
      <a href="#pricing" className="text-[#1E2238] hover:text-[#1E2238]/80 transition-colors font-medium">
        Pricing
      </a>
    </div>
  );
};