import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Menu, X } from 'lucide-react';
import { useScrollVisibility } from '../../lib/hooks/useScrollVisibility';
import { NavLinks } from './NavLinks';
import { MobileMenu } from './MobileMenu';

export const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isVisible = useScrollVisibility();

  const handleLoginClick = () => {
    window.location.href = 'https://brandrdesign.xbrandr.io/signin';
  };

  const navClasses = isVisible ? 'translate-y-0' : '-translate-y-full';

  return (
    <nav className={`fixed w-full z-50 transition-all duration-300 ${navClasses} bg-white shadow-sm`}>
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <Link to="/" className="flex items-center gap-3">
            <img 
              src="https://content.app-sources.com/s/498134316199503221/uploads/Images/brandr_design_logo_3-4111031.webp?format=webp"
              alt="Brandr Design Logo"
              className="w-10 h-7 object-contain"
            />
            <span className="text-2xl font-medium text-[#1E2238]">Brandr Design</span>
          </Link>

          {/* Desktop Navigation */}
          <div className="hidden md:flex items-center gap-8">
            <NavLinks />
            <button 
              onClick={handleLoginClick}
              className="px-4 py-2 bg-[#1E2238] text-white hover:bg-[#1E2238]/90 transition-colors rounded-lg"
            >
              Login
            </button>
          </div>

          {/* Mobile Menu Button */}
          <button 
            onClick={() => setIsMenuOpen(!isMenuOpen)}
            className="md:hidden text-[#1E2238]"
            aria-label={isMenuOpen ? 'Close menu' : 'Open menu'}
          >
            {isMenuOpen ? (
              <X className="w-6 h-6" />
            ) : (
              <Menu className="w-6 h-6" />
            )}
          </button>
        </div>

        {/* Mobile Menu */}
        <MobileMenu 
          isOpen={isMenuOpen} 
          onClose={() => setIsMenuOpen(false)}
          onLoginClick={handleLoginClick}
        />
      </div>
    </nav>
  );
};