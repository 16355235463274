import { motion } from 'framer-motion';

export const services = [
  {
    title: "Brand Identity Design",
    description: "Full brand identity system including logo, color palette, typography, and brand guidelines",
    image: "https://images.unsplash.com/photo-1634942537034-2531766767d1?q=80&w=2000&auto=format&fit=crop",
    tags: ["BRANDING", "STRATEGY", "GUIDELINES"]
  },
  {
    title: "Social Media Design",
    description: "Engaging visual content for all social platforms, including posts, stories, and ads",
    image: "https://images.unsplash.com/photo-1611162617474-5b21e879e113?q=80&w=2000&auto=format&fit=crop",
    tags: ["SOCIAL", "MARKETING", "CONTENT"]
  },
  {
    title: "Website UI Design",
    description: "Custom website interfaces with focus on user experience and conversion",
    image: "https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?q=80&w=2000&auto=format&fit=crop",
    tags: ["UI/UX", "WEB", "INTERFACE"]
  },
  {
    title: "Marketing Collateral",
    description: "Business cards, letterheads, brochures, flyers, and presentation templates",
    image: "https://images.unsplash.com/photo-1586281380349-632531db7ed4?q=80&w=2000&auto=format&fit=crop",
    tags: ["PRINT", "MARKETING", "COLLATERAL"]
  },
  {
    title: "Digital Product Design",
    description: "User interfaces for web and mobile applications",
    image: "https://images.unsplash.com/photo-1581291518633-83b4ebd1d83e?q=80&w=2000&auto=format&fit=crop",
    tags: ["PRODUCT", "UI/UX", "DIGITAL"]
  }
];

export const tagColors = {
  BRANDING: "#0066FF",
  STRATEGY: "#FF3366",
  GUIDELINES: "#FDBA12",
  SOCIAL: "#00CC88",
  MARKETING: "#9966FF",
  CONTENT: "#FF6633",
  "UI/UX": "#33CCFF",
  WEB: "#FF99CC",
  INTERFACE: "#66FF99",
  PRINT: "#FFCC33",
  COLLATERAL: "#CC99FF",
  PRODUCT: "#FF6699",
  DIGITAL: "#33FFCC"
};