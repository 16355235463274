import React from 'react';
import { useProjectsContext } from './ProjectsContext';

export const ProjectsFilter = () => {
  const { categories, selectedCategory, setSelectedCategory } = useProjectsContext();

  return (
    <section className="py-8">
      <div className="container mx-auto px-4">
        <div className="flex justify-center gap-8">
          {categories.map((category) => (
            <button
              key={category}
              onClick={() => setSelectedCategory(category)}
              className={`text-sm font-medium transition-colors ${
                selectedCategory === category
                  ? 'text-[#FDBA12]'
                  : 'text-gray-400 hover:text-white'
              }`}
            >
              {category}
            </button>
          ))}
        </div>
      </div>
    </section>
  );
};