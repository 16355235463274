import React from 'react';
import { ProjectCard } from './ProjectCard';
import { useProjectsContext } from './ProjectsContext';

export const ProjectsGrid = () => {
  const { filteredProjects } = useProjectsContext();

  return (
    <section className="py-20">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {filteredProjects.map((project, index) => (
            <ProjectCard
              key={index}
              project={project}
              index={index}
            />
          ))}
        </div>
      </div>
    </section>
  );
};