import { PricingPlan } from './types';

export const usePricingPlans = (): PricingPlan[] => {
  return [
    {
      name: "Founder",
      price: "1,999",
      description: "Perfect for growing businesses",
      highlight: false,
      features: [
        "Unlimited design requests",
        "One request at a time",
        "48-hour turnaround",
        "Unlimited revisions",
        "Source files included",
        "Brand guidelines adherence",
        "Basic illustrations"
      ]
    },
    {
      name: "Enterprise",
      price: "2,999",
      highlight: true,
      description: "For businesses that need more speed",
      features: [
        "Everything in Founder",
        "Two requests at a time",
        "24-hour turnaround",
        "Priority support",
        "Custom illustrations",
        "Motion graphics",
        "Dedicated project manager",
        "Weekly progress calls"
      ]
    }
  ];
};