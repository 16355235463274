import React from 'react';
import { motion } from 'framer-motion';
import { features } from './data';

export const Features = () => {
  return (
    <section className="relative bg-black py-24">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-20">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block bg-white/10 px-4 py-1 mb-6"
          >
            <span className="text-white text-sm">WHAT WE OFFER</span>
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-5xl md:text-6xl font-medium text-white leading-tight mb-6"
          >
            Make every step
            <br />
            user-centric
          </motion.h2>
        </div>

        {/* Features List */}
        <div className="max-w-6xl mx-auto">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="group relative mb-32 last:mb-0"
            >
              <div className="flex flex-col md:flex-row items-start gap-8 relative">
                {/* Left Column - Icon & Title */}
                <div className="md:w-1/3">
                  <div className="sticky top-8">
                    <div 
                      className="w-20 h-20 rounded-full flex items-center justify-center mb-6 transition-transform duration-300 group-hover:scale-110"
                      style={{ 
                        backgroundColor: `${feature.color}10`,
                        color: feature.color
                      }}
                    >
                      <feature.icon className="w-10 h-10" />
                    </div>
                    <h3 className="text-3xl font-medium text-white mb-4">
                      {feature.title}
                    </h3>
                    <div 
                      className="h-1 w-16 transition-all duration-300 group-hover:w-24"
                      style={{ backgroundColor: feature.color }}
                    />
                  </div>
                </div>

                {/* Right Column - Description & Visual */}
                <div className="md:w-2/3">
                  <div className="bg-white/5 backdrop-blur-sm p-8 rounded-2xl">
                    <p className="text-gray-400 text-lg leading-relaxed mb-8">
                      {feature.description}
                    </p>
                    
                    {/* Feature Visual */}
                    <motion.div 
                      initial={{ scale: 0.95, opacity: 0 }}
                      whileInView={{ scale: 1, opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ delay: 0.2 }}
                      className="relative aspect-[16/9] rounded-lg overflow-hidden group"
                    >
                      <div className="absolute inset-0 bg-gradient-to-t from-black/50 to-transparent z-10" />
                      <img
                        src={feature.image}
                        alt={feature.imageAlt}
                        className="w-full h-full object-cover transition-transform duration-700 group-hover:scale-110"
                      />
                      <div 
                        className="absolute bottom-0 left-0 right-0 p-6 transform translate-y-full group-hover:translate-y-0 transition-transform duration-500"
                        style={{ 
                          background: `linear-gradient(to top, ${feature.color}90, transparent)`
                        }}
                      >
                        <p className="text-white text-sm">
                          {feature.imageAlt}
                        </p>
                      </div>
                    </motion.div>
                  </div>
                </div>
              </div>

              {/* Connecting Line */}
              {index !== features.length - 1 && (
                <div 
                  className="absolute -bottom-16 left-[10%] w-[1px] h-32 bg-gradient-to-b from-white/10 to-transparent"
                />
              )}
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};