import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight, Sparkles } from 'lucide-react';
import { scrollToSection } from '../../lib/utils/scroll';

export const CTA = () => {
  const handleStartSubscription = () => {
    scrollToSection('pricing');
  };

  const handleDemoCall = () => {
    window.location.href = 'https://brandrdesign.xbrandr.io/call';
  };

  return (
    <section className="relative py-24">
      {/* Decorative Elements */}
      <div className="absolute inset-0">
        {/* Grid Lines */}
        <div className="absolute inset-0">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1 }}
            className="absolute top-0 left-1/4 w-[1px] h-full bg-white"
          />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1 }}
            className="absolute top-0 right-1/4 w-[1px] h-full bg-white"
          />
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1 }}
            className="absolute top-1/2 left-0 w-full h-[1px] bg-white"
          />
        </div>

        {/* Diagonal Lines */}
        {[1, 2, 3].map((index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.1 }}
            className="absolute w-1/4 h-[1px] bg-white"
            style={{
              top: '50%',
              left: `${index * 25}%`,
              transform: 'rotate(45deg)',
              transformOrigin: 'left center'
            }}
          />
        ))}
      </div>

      <div className="container mx-auto px-4">
        <div className="relative max-w-4xl mx-auto">
          {/* Content Container */}
          <div className="text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="inline-flex items-center gap-2 mb-8"
            >
              <Sparkles className="w-4 h-4 text-[#FDBA12]" />
              <span className="text-sm tracking-wider text-gray-400">START CREATING TODAY</span>
            </motion.div>

            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-5xl md:text-7xl font-medium mb-6 text-white"
            >
              Ready to Transform Your
              <br />
              Design Process?
            </motion.h2>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="text-xl text-gray-400 mb-12"
            >
              Join hundreds of companies who have already revolutionized their design workflow
            </motion.p>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="flex flex-col sm:flex-row gap-4 justify-center"
            >
              <button 
                onClick={handleStartSubscription}
                className="group relative py-3 px-8 bg-white text-black font-medium overflow-hidden hover:bg-white/90 transition-colors rounded-lg"
              >
                <span className="relative flex items-center justify-center gap-2">
                  Start your subscription
                  <ArrowRight className="w-5 h-5 group-hover:translate-x-1 transition-transform" />
                </span>
              </button>
              
              <button 
                onClick={handleDemoCall}
                className="py-3 px-8 text-lg font-medium border border-white/20 text-white hover:bg-white/5 transition-all duration-300 rounded-lg"
              >
                Schedule a demo
              </button>
            </motion.div>

            {/* Trust Indicators */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="mt-16 flex flex-col sm:flex-row items-center justify-center gap-8 text-sm text-gray-400"
            >
              <div className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-[#FDBA12]" />
                Trusted by 500+ brands
              </div>
              <div className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-[#0066FF]" />
                14-day money-back guarantee
              </div>
              <div className="flex items-center gap-2">
                <span className="w-2 h-2 rounded-full bg-white" />
                Award-winning support
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </section>
  );
};