import React from 'react';
import * as Sentry from "@sentry/react";

interface Props {
  children: React.ReactNode;
}

export const ErrorBoundary = ({ children }: Props) => {
  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => (
        <div className="min-h-screen bg-black flex items-center justify-center">
          <div className="max-w-xl mx-auto px-4 py-8 text-center">
            <h1 className="text-3xl font-medium text-white mb-4">Something went wrong</h1>
            <p className="text-gray-400 mb-6">We've been notified about this issue and are working to fix it.</p>
            <p className="text-sm text-gray-500">{error.message}</p>
          </div>
        </div>
      )}
    >
      {children}
    </Sentry.ErrorBoundary>
  );
};