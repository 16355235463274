import React from 'react';
import { motion } from 'framer-motion';
import { Star, Award, Shield } from 'lucide-react';

export const HeroEyebrow = () => {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="flex flex-wrap justify-center gap-1.5 sm:gap-4 mb-4 sm:mb-8 max-w-[90%] sm:max-w-none mx-auto"
    >
      <div className="flex items-center gap-1 sm:gap-1.5 bg-gradient-to-r from-[#FF460C]/5 to-[#FF460C]/10 backdrop-blur-sm px-2 sm:px-4 py-1 sm:py-2 rounded-full border border-[#FF460C]/10">
        <Star className="w-3 h-3 sm:w-4 sm:h-4 text-[#FF460C]" />
        <span className="text-[11px] sm:text-sm font-medium text-[#1E2238] whitespace-nowrap">5.0 Customer Rating</span>
      </div>
      <div className="flex items-center gap-1 sm:gap-1.5 bg-gradient-to-r from-[#FF460C]/5 to-[#FF460C]/10 backdrop-blur-sm px-2 sm:px-4 py-1 sm:py-2 rounded-full border border-[#FF460C]/10">
        <Award className="w-3 h-3 sm:w-4 sm:h-4 text-[#FF460C]" />
        <span className="text-[11px] sm:text-sm font-medium text-[#1E2238] whitespace-nowrap">Award-winning Agency</span>
      </div>
      <div className="flex items-center gap-1 sm:gap-1.5 bg-gradient-to-r from-[#FF460C]/5 to-[#FF460C]/10 backdrop-blur-sm px-2 sm:px-4 py-1 sm:py-2 rounded-full border border-[#FF460C]/10">
        <Shield className="w-3 h-3 sm:w-4 sm:h-4 text-[#FF460C]" />
        <span className="text-[11px] sm:text-sm font-medium text-[#1E2238] whitespace-nowrap">Enterprise Ready</span>
      </div>
    </motion.div>
  );
};