import { Project } from './types';

export const projects: Project[] = [
  {
    title: "Express Brand Identity",
    description: "Complete brand identity system with logo design and guidelines",
    category: "Branding",
    client: "Express Corp",
    image: "https://framerusercontent.com/images/VfoevtpEMufqF4vhoklWcHyG0U.png?scale-down-to=1024"
  },
  {
    title: "E-commerce Platform",
    description: "Modern e-commerce solution with seamless user experience",
    category: "Web Design",
    client: "Shop Direct",
    image: "https://framerusercontent.com/images/QvJQoJ6755I4M9t4DpAtSjCnfnU.png?scale-down-to=512"
  },
  {
    title: "Marketing Campaign",
    description: "Integrated digital marketing campaign across multiple channels",
    category: "Marketing",
    client: "Global Tech",
    image: "https://content.app-sources.com/s/188398406015333501/uploads/Camarote_N1/Grupo_182_copy-7534703.webp?format=webp"
  },
  {
    title: "Mobile App Design",
    description: "Intuitive mobile application interface and user experience",
    category: "UI/UX",
    client: "AppCo",
    image: "https://content.app-sources.com/s/188398406015333501/uploads/SA_instituto/SA5-8628892.webp?format=webp"
  },
  {
    title: "Corporate Website",
    description: "Professional website design for enterprise solutions",
    category: "Web Design",
    client: "Enterprise Inc",
    image: "https://content.app-sources.com/s/188398406015333501/uploads/SA_instituto/SA3-8628892.webp?format=webp"
  },
  {
    title: "Brand Campaign",
    description: "Strategic brand awareness campaign and visual identity",
    category: "Marketing",
    client: "Innovate Co",
    image: "https://content.app-sources.com/s/188398406015333501/uploads/SA_instituto/SA8-8629815.webp?format=webp"
  }
];