import React from 'react';
import { ProjectsProvider } from '../components/projects/ProjectsContext';
import { ProjectsHeader } from '../components/projects/ProjectsHeader';
import { ProjectsFilter } from '../components/projects/ProjectsFilter';
import { ProjectsGrid } from '../components/projects/ProjectsGrid';

export const Projects = () => {
  return (
    <ProjectsProvider>
      <div className="min-h-screen bg-black pt-32">
        <ProjectsHeader />
        <ProjectsFilter />
        <ProjectsGrid />
      </div>
    </ProjectsProvider>
  );
};