import React from 'react';
import { motion } from 'framer-motion';

const teamMembers = [
  {
    name: "Fausto Lagares",
    role: "CEO Brandr Design",
    image: "https://assets-global.website-files.com/6422422695ae3371d5269bdf/64384fa9b1b8c3a915de297e_Fausto%20Lagares%20Profile%20Photo.jpeg"
  },
  {
    name: "Sarah Chen",
    role: "Creative Director",
    image: "https://cdn.prod.website-files.com/66c6873eb89c4fbac3e22a46/66c6b13955af0b2383eed963_Testimonial%2520Images%252001-p-800.jpg"
  },
  {
    name: "Michael Rodriguez",
    role: "Lead Designer",
    image: "https://cdn.prod.website-files.com/66c6873eb89c4fbac3e22a46/66c6b13955af0b2383eed8ed_Testimonial%2520Images%252002-p-800.jpg"
  },
  {
    name: "Emily Thompson",
    role: "UI/UX Designer",
    image: "https://cdn.prod.website-files.com/66c6873eb89c4fbac3e22a46/66c6b13955af0b2383eed911_Testimonial%2520Images%252003-p-800.jpg"
  },
  {
    name: "David Kim",
    role: "Motion Designer",
    image: "https://cdn.prod.website-files.com/66c6873eb89c4fbac3e22a46/66c6b13855af0b2383eed8c1_Testimonial%2520Images%252004-p-800.jpg"
  },
  {
    name: "Lisa Wang",
    role: "Brand Strategist",
    image: "https://cdn.prod.website-files.com/66c6873eb89c4fbac3e22a46/66c6b13855af0b2383eed858_Testimonial%20Image%2003.jpg"
  },
  {
    name: "James Wilson",
    role: "Project Manager",
    image: "https://cdn.prod.website-files.com/66c6873eb89c4fbac3e22a46/66c6b13855af0b2383eed86d_Testimonial%20Image%2002.jpg"
  },
  {
    name: "Anna Martinez",
    role: "Content Designer",
    image: "https://cdn.prod.website-files.com/66c6873eb89c4fbac3e22a46/66c6b13955af0b2383eed963_Testimonial%2520Images%252001-p-800.jpg"
  }
];

export const Team = () => {
  return (
    <section className="relative bg-black py-24">
      <div className="container mx-auto px-4">
        {/* Header */}
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="inline-block bg-white/10 px-4 py-1 mb-6"
          >
            <span className="text-white text-sm">OUR TEAM</span>
          </motion.div>
          
          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.1 }}
            className="text-4xl lg:text-5xl font-medium text-white leading-tight mb-6"
          >
            Meet the Creative Minds
          </motion.h2>
          
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg max-w-2xl mx-auto"
          >
            Our talented team of designers and strategists dedicated to transforming your brand
          </motion.p>
        </div>

        {/* Team Grid */}
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
          {teamMembers.map((member, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="group relative"
            >
              <div className="aspect-[9/16] overflow-hidden rounded-lg relative">
                <img
                  src={member.image}
                  alt={member.name}
                  className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black via-black/50 to-transparent">
                  <div className="absolute bottom-0 left-0 right-0 p-6">
                    <h3 className="text-xl font-medium text-white mb-1">{member.name}</h3>
                    <p className="text-gray-400">{member.role}</p>
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </section>
  );
};